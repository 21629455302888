$(function(){

  $("#gnav-btn").click(function () {
    $(this).toggleClass('active');
    $("#gnav-menu").toggleClass('active');
    $("#gnav-bg").toggleClass('active');
  });
  $("#gnav-menu a").click(function () {
    $("#gnav-btn").removeClass('active');
    $("#gnav-menu").removeClass('active');
    $("#gnav-bg").removeClass('active');
  });

  


  // カモメ飛行トリガー
  $(".about__eagull__click").click(function(){
    $(this).hide();

    $.when(
      $(".about__eagull__img-pc").attr('src', $(".about__eagull__img-pc").attr('src').replace('anime_seagull', 'anime_seagull_movie')),
      $(".about__eagull__img-sp").attr('src', $(".about__eagull__img-sp").attr('src').replace('anime_seagull', 'anime_seagull_movie'))
    ).done(function(){ 
      setTimeout(function(){
        $(".about__eagull__img-pc").attr('src', $(".about__eagull__img-pc").attr('src').replace('anime_seagull_movie', 'anime_seagull'))
        $(".about__eagull__click").show();
      },8200);
      setTimeout(function(){
        $(".about__eagull__img-sp").attr('src', $(".about__eagull__img-sp").attr('src').replace('anime_seagull_movie', 'anime_seagull'))
        $(".about__eagull__click").show();
      },5000);
    });
  });

  // KVトリガー
  $("#mv-img").attr('src', $("#mv-img").attr('src').replace('anime_kv_stay.png', 'anime_kv.png'));
  $.when(
    $("#mv-img").attr('src', $("#mv-img").attr('src').replace('anime_kv_stay.png', 'anime_kv.png'))
  ).done(function(){ 
    setTimeout(function(){
      $("#mv-img").attr('src', $("#mv-img").attr('src').replace('anime_kv.png', 'anime_kv_stay.png'))
    },3200)
  });

  $("#mv-img").click(function(){
    $.when(
      $(this).attr('src', $(this).attr('src').replace('anime_kv_stay.png', 'anime_kv_tap.png'))
    ).done(function(){ 
      setTimeout(function(){
        $("#mv-img").attr('src', $("#mv-img").attr('src').replace('anime_kv_tap.png', 'anime_kv_stay.png'))
      },1500)
    });
  });

  // KV TickTok 吹き出しアニメーション
  var interval =2000; // 切り替わりの間隔（ミリ秒）
  var fade_speed = 0;// フェード処理の早さ（ミリ秒）
  $(".mv__tiktok__txt-slide img").hide();
  $(".mv__tiktok__txt-slide img:first").addClass("active").show();

  var changeImage = function(){
      var $active = $(".mv__tiktok__txt-slide img.active");
      var $next = $active.next("img").length?$active.next("img"):$(".mv__tiktok__txt-slide img:first");
    
      $active.fadeOut(fade_speed).removeClass("active");
      $next.fadeIn(fade_speed).addClass("active");
  }
  setInterval(changeImage,interval);


  // var btn = $('#fix-btn');
  // //navの位置    
  // var btnTop = btn.offset().top;
  // var btnH = btn.outerHeight();
  // var btnBottom = btnTop + btnH;
  // console.log(btnBottom);



  //スクロールするたびに実行
  $(window).scroll(function () {
    // 画面内に入ったら実行
    $('.scroll-effect').each(function () {
      var hit = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var wHeight = $(window).height();
      var customTop = 100;
      if (typeof $(this).data('effect') !== 'undefined') {
        // data-effect="任意の値" が設定されている場合
        customTop = $(this).data('effect');
      }
      if (hit + customTop < wHeight + scroll) {
        $(this).addClass("active");
      }
    });
    // 
    mvHeight = $(".mv").height();
    if ($(window).scrollTop() > mvHeight) {
      // スクロールの位置がファーストビューより下の場合にclassを付与
      $("#gnav-btn").css('opacity','0');
      $("#gnav-btn").delay(100).addClass("fixed");
    } else {
      // スクロールの位置がファーストビューより上の場合にclassを外す
      $("#gnav-btn").css('opacity','1');
      $("#gnav-btn").removeClass("fixed");
    }
  });


});
